import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import CustomLink from "../../shared/custom-link/CustomLink";
import TextFormatter from "../../../utils/TextFormatter";
import './styles.scss';

export type ArticleCardProps = {
  header: string,
  title: string,
  publishedAt: string,
  updatedAt: string,
  description: {
    data: {
      description: string,
    }
  },
  image: GatsbyImageCustomType,
  users_permissions_user?: {
    username: string,
    userAvatar: GatsbyImageCustomType,
  }
}

const ArticleCard = ({article}: { article: ArticleCardProps }) => {
  const date = new Date(article.publishedAt).toLocaleDateString('pl-PL', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
  const user = article.users_permissions_user;
  const slug = TextFormatter.slugify(article.header);
  const formattedTitle = TextFormatter.concatOrphans(article.title) || article.title;

  return (
    <article>
      <CustomLink isInternal to={`/blog/${slug}`} className={'article-card'}>
        <div className="article-image">
          <GatsbyImage alt={article.image.alternativeText}
                       image={article.image.localFile.childImageSharp.gatsbyImageData}/>
        </div>
        <header>
          <p className={'article-date'}>{date}</p>
          <h2 className={'article-title'}>{article.header}</h2>
          <p className={'article-excerpt'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
          <div className={'article-footer'}>
            {user &&
              <div className={'article-author'}>
                <GatsbyImage className={'rounded-full'}
                             alt={user.userAvatar.alternativeText}
                             image={user.userAvatar.localFile.childImageSharp.gatsbyImageData}/>
                <span>{user?.username}</span>
              </div>
            }
            <button type="button" className={'font-head'}>czytaj więcej</button>
          </div>
        </header>
      </CustomLink>
    </article>
  )
}

export default ArticleCard;
