import React from "react";
import ArticleCard, {ArticleCardProps} from "./article-card/ArticleCard";
import {SiteMetadata} from "../../types/site-metadata.type";
import Paginator from "../shared/paginator/Paginator";
import TextFormatter from "../../utils/TextFormatter";

export type ArticlesListProps = {
  allStrapiBlogArticle: {
    nodes: ArticleCardProps[],
  }
}

export type BlogProps = {
  strapiBlog: {
    title: string,
    description: string,
    siteMetadata: SiteMetadata
  }
}

type BlogPageProps = {
  currentPage: number,
  numPages: number,
} & BlogProps & ArticlesListProps

const BlogComponent = ({currentPage, numPages, strapiBlog, allStrapiBlogArticle}: BlogPageProps) => {
  const {title, description} = strapiBlog;

  const formattedTitle = TextFormatter.concatOrphans(title) || title
  const formattedDescription = TextFormatter.concatOrphans(description) || description

  return(
    <section className={'pb-20 flex flex-col items-center'}>
      <div className={'bg-neutrals-90 pt-24 pb-12 px-5 sm:px-10 w-full'}>
        <div className={'flex flex-col max-w-[1100px] mx-auto text-center'}>
          <h1 className={'font-head text-4xl lg:text-5xl text-black mb-4'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
          <p className={'text-lg text-neutrals-40'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>
      </div>

      <div className={'articles pt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-5 max-w-[1040px] px-5 sm:px-0'}>
        {allStrapiBlogArticle.nodes.map((article, index) => <ArticleCard key={index} article={article}/>)}
      </div>

      <Paginator className={'mt-10 mx-auto'} currentPage={currentPage} numPages={numPages}/>
    </section>
  )
}

export default BlogComponent;
