import React from "react";
import ContactInformation from './contact-information';

export interface StructuredDataBreadcrumb {
  name: string;
  item?: string;
}

interface BreadcrumbItem {
  "@type": string,
  position: number,
  name: string,
  item?: string
}

const StructuredData = (breadcrumbs?: StructuredDataBreadcrumb[], own?: object[]) => {
  let structuredData = {
    "@context": "https://schema.org/",
    "@graph": [
      ...(own || []),
      {
        "@type": "Organization",
        "@id": ContactInformation.websiteUrl,
        "name": ContactInformation.company.name,
        "url": ContactInformation.websiteUrl,
        "email": ContactInformation.email,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": ContactInformation.company.address.street,
          "addressLocality": ContactInformation.company.address.city,
          "addressRegion": ContactInformation.company.address.region,
          "postalCode": ContactInformation.company.address.zipCode,
          "addressCountry": ContactInformation.company.address.country
        },
        "logo": {
          "@type": "ImageObject",
          "url": ContactInformation.websiteUrl + "logo.png",
        },
        "contactPoint": [
          {
            "@type": "ContactPoint",
            "telephone": ContactInformation.phone,
            "email": ContactInformation.email,
            "contactType": "customer service"
          },
          {
            "@type": "ContactPoint",
            "telephone": ContactInformation.phoneSupport,
            "email": ContactInformation.emailSupport,
            "contactType": "customer support"
          }
        ],
      },
    ]
  };

  if (breadcrumbs) {
    const breadcrumbItems: object[] = [];

    let position = 1;
    breadcrumbs.forEach(crumb => {
      const crumbItem: BreadcrumbItem = {
        "@type": "ListItem",
        position: position++,
        name: crumb.name,
      }

      if (crumb.item) {
        crumbItem.item = crumb.item;
      }

      breadcrumbItems.push(crumbItem);
    })

    structuredData['@graph'].push({
      "@type": "BreadcrumbList",
      itemListElement: breadcrumbItems
    })
  }

  return (
    <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
  )
}

export default StructuredData;
