import React from "react";
import CustomLink from '../custom-link/CustomLink';

type PaginatorProps = {
  numPages: number,
  currentPage: number,
  className?: string,
  limit?: number,
}

const Paginator = ({numPages, currentPage, className, limit = 2}: PaginatorProps) => {
  if (numPages <= 1) return null;

  let pages = Array.from({length: numPages}).map((_, i) => i + 1);
  const sliceStartIndex = currentPage - limit < 0 ? 0 : currentPage - limit - 1;
  const sliceEndIndex = currentPage < limit ? currentPage + limit  + (limit - currentPage) : currentPage + limit

  if (currentPage <= limit) {
    pages = pages.slice(0, (limit * 2) + 1);
  } else if (currentPage >= numPages - limit) {
    pages = pages.slice(numPages - ((limit * 2) + 1), numPages);
  } else {
    pages = pages.slice(sliceStartIndex, sliceEndIndex);
  }

  const pageLink = (index: number) => {
    if (index === 1) {
      return ('/blog');
    }

   return (`/blog/${index}`);
  }

  const previousPageLink = () => {
    return pageLink(currentPage - 1)
  }

  const nextPageLink = () => {
    return pageLink(currentPage + 1)
  }

  const buttonClasses = 'cursor-pointer w-7 h-7 sm:w-11 sm:h-11 border-y border-l flex items-center justify-center border-neutrals-80'
  return(
    <div className={'flex flex-row ' + className}>
      {currentPage !== 1 ?
        <CustomLink to={pageLink(1)} isInternal className={buttonClasses}>
          <span className={`mtr-icon !text-sm sm:!text-base text-black`}>keyboard_double_arrow_left</span>
        </CustomLink>
      :
        <button type="button" className={buttonClasses + ' cursor-default'} disabled>
          <span className={`mtr-icon !text-sm sm:!text-base text-neutrals-80`}>keyboard_double_arrow_left</span>
        </button>
      }
      {currentPage !== 1 ?
        <CustomLink to={previousPageLink()} isInternal className={buttonClasses} >
          <span className={`mtr-icon !text-sm sm:!text-base text-black`}>chevron_left</span>
        </CustomLink>
      :
        <button type="button" className={buttonClasses + ' cursor-default'} disabled>
          <span className={`mtr-icon !text-sm sm:!text-base text-neutrals-80`}>chevron_left</span>
        </button>
      }
      {pages.map((page, i) => {
        return(
          <CustomLink to={pageLink(page)} isInternal className={buttonClasses + `${currentPage === page ? ' cursor-default pointer-events-none bg-primary-100/50 border-primary-100' : ''}`} key={i}>
            <span className={`text-sm sm:text-base text-black`}>{page}</span>
          </CustomLink>
        )
      })}
      {currentPage !== numPages ?
        <CustomLink to={nextPageLink()} isInternal className={buttonClasses}>
          <span className={`mtr-icon !text-sm sm:!text-base ${currentPage === numPages ? ' text-neutrals-80 ' : 'text-black'}`}>chevron_right</span>
        </CustomLink>
      :
        <button type="button" className={buttonClasses + ' cursor-default'} disabled>
          <span className={`mtr-icon !text-sm sm:!text-base text-neutrals-80`}>chevron_right</span>
        </button>
      }
      {currentPage !== numPages ?
        <CustomLink to={pageLink(numPages)} isInternal className={buttonClasses + ' border-r'}>
          <span className={`mtr-icon !text-sm sm:!text-base ${currentPage === numPages ? ' text-neutrals-80 ' : 'text-black'}`}>keyboard_double_arrow_right</span>
        </CustomLink>
      :
        <button type="button" className={buttonClasses + ' border-r cursor-default'} disabled>
          <span className={`mtr-icon !text-sm sm:!text-base text-neutrals-80`}>keyboard_double_arrow_right</span>
        </button>
      }
    </div>
  )
}

export default Paginator;
