interface IContactInformation {
  company: ICompany;
  websiteUrl: string;
  phone: string;
  phoneSupport: string;
  phoneCooperation: string;
  email: string;
  emailSupport: string;
  emailCooperation: string;
}

interface ICompany {
  name: string;
  vatNumber: string;
  krsNumber: string;
  regonNumber: string;
  address: IAddress;
}

interface IAddress {
  street: string;
  zipCode: string;
  city: string;
  region: string;
  country: string;
  lat: number;
  lng: number;
}

const ContactInformation: IContactInformation = {
  company: {
    name: 'MYTRACKO Sp. z o.o.',
    vatNumber: 'PL6222837322',
    regonNumber: '389959505',
    krsNumber: '0000922143',
    address: {
      street: 'Szafirowa 2/8',
      zipCode: '63-400',
      city: 'Ostrów Wielkopolski',
      region: 'Wielkopolska',
      country: 'Polska',
      lat: 51.66348840006489,
      lng: 17.83587501938352,
    }
  },
  websiteUrl: 'https://mytracko.pl/',
  phone: '+48 453 402 402',
  phoneSupport: '+48 453 402 402',
  phoneCooperation: '+48 453 402 402',
  email: 'kontakt@mytracko.pl',
  emailSupport: 'wsparcie@mytracko.pl',
  emailCooperation: 'wspolpraca@mytracko.pl',
}

export default ContactInformation;
