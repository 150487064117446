import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../components/Layout";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {BreadcrumbType} from "../components/shared/breadcrumb/Breadcrumb";
import BlogComponent, {ArticlesListProps, BlogProps} from "../components/blog/Blog";
import StructuredData from '../assets/structured-data';

type PageContextType = {
  breadcrumb: BreadcrumbType,
  currentPage: number,
  limit: number,
  numPages: number,
  skip: number
}

type BlogPageProps = BlogProps & ArticlesListProps & SiteMetadataConfigProps

const Blog = ({data: {strapiBlog, allStrapiBlogArticle}, pageContext}: PageProps<BlogPageProps, PageContextType>) => {
  const {siteMetadata} = strapiBlog;

  if (pageContext.currentPage > 1 && pageContext.breadcrumb.crumbs.length > 2) {
    pageContext.breadcrumb.crumbs.pop();
  }

  return(
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={siteMetadata.crumbLabel}>
      <BlogComponent allStrapiBlogArticle={allStrapiBlogArticle} strapiBlog={strapiBlog} currentPage={pageContext.currentPage} numPages={pageContext.numPages}/>
    </Layout>
  )
}

export default Blog;

export const Head = (props: HeadProps<BlogPageProps, PageContextType>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  let {title, description} = props.data.strapiBlog.siteMetadata;
  if (props.pageContext.currentPage > 1) {
    title += ` – strona ${props.pageContext.currentPage}`;
    description += " – strona ${props.pageContext.currentPage}";
  }

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: title,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allStrapiBlogArticle(sort: {fields: publishedAt, order: DESC}, limit: $limit, skip: $skip) {
      nodes {
        header
        title
        publishedAt
        description {
          data {
            description
          }
        }
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, width: 320, height: 205)
            }
          }
        }
        users_permissions_user {
          username
          userAvatar {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: CONSTRAINED
                  height: 30
                  width: 30
                  transformOptions: {cropFocus: NORTH}
                )
              }
            }
          }
        }
      }
    }
    strapiBlog {
      title
      description
      siteMetadata {
        description
        crumbLabel
        title
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
  }
`
